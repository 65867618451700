const AdventureTypes = {
    GET_INFO: 'GET_INFO',
    UPDATE_INFO: 'UPDATE_INFO',
    UPDATE_UNSAVE: 'UPDATE_AVENTURE_TOUR_UNSAVE',
    DELETE_UNSAVE: 'DELETE_AVENTURE_TOUR_UNSAVE',
    GET_ALL_ADVENTURE_TYPE: "GET_ALL_ADVENTURE_TYPE",
    ADD_ADVENTURE_TYPE: "ADD_ADVENTURE_TYPE",
    UPDATE_ADVENTURE_TYPE: "UPDATE_ADVENTURE_TYPE",
    DELETE_ADVENTURE_TYPE: "DELETE_ADVENTURE_TYPE",
    GET_ADVENTURE_TOUR: "GET_ADVENTURE_TOUR",
    GET_ADVENTURE_TOUR_DETAIL: "GET_ADVENTURE_TOUR_DETAIL",
    ADD_ADVENTURE_TOUR: "ADD_ADVENTURE_TOUR",
    UPDATE_ADVENTURE_TOUR: "UPDATE_ADVENTURE_TOUR",
    DELETE_ADVENTURE_TOUR: "DELETE_ADVENTURE_TOUR",
    UPDATE_CURRENT_STEP:"UPDATE_CURRENT_ADVENTURE_STEP",
    GET_DETAIL_SUCCESS:"GET_DETAIL_ADVANTURE_SUCCESS"
        
}

export default AdventureTypes