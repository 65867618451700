const TeamBuildingTypeTypes = {
    ADD: "ADD_TEAMBUILDINGTYPE",
    DELETE: "DELETE_TEAMBUILDINGTYPE",
    ERROR: "ERROR_TEAMBUILDINGTYPE",
    GET_ALL: "GET_ALL_TEAMBUILDINGTYPE",
    GET_INFO: "GET_INFO_TEAMBUILDINGTYPE",
    UPDATE: "UPDATE_TEAMBUILDINGTYPE", // final step
    UPDATE_INFO: "UPDATE_INFO_TEAMBUILDINGTYPE",
}

export default TeamBuildingTypeTypes