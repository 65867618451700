const TeamBuildingTypes = {
    ADD: "ADD_TEAM_BUILDING",
    DELETE: "DELETE_TEAM_BUILDING",
    ERROR: "ERROR_TEAM_BUILDING",
    GET_ALL: "GET_ALL_TEAM_BUILDING",
    GET_DETAIL: "GET_DETAIL_TEAM_BUILDING",
    GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS_TEAM_BUILDING",
    UPDATE: "UPDATE_TEAM_BUILDING", // final step
    UPDATE_UNSAVE: "UPDATE_UNSAVE_TEAM_BUILDING",
    DELETE_UNSAVE: 'DELETE_UNSAVE_TEAM_BUILDING',
    UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP_TEAM_BUILDING',
}

export default TeamBuildingTypes