const TourTypes = {
    ADD: "ADD_TOUR",
    DELETE: "DELETE_TOUR",
    ERROR: "ERROR_TOUR",
    GET_ALL: "GET_ALL_TOUR",
    GET_DETAIL: "GET_DETAIL_TOUR",
    GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS_TOUR",
    UPDATE: "UPDATE_TOUR", // final step
    UPDATE_UNSAVE: "UPDATE_UNSAVE_TOUR",
    DELETE_UNSAVE: 'DELETE_UNSAVE_TOUR',
    UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP_TOUR',
}

export default TourTypes