const CustomerTypes = {
    ADD: "ADD_CUSTOMER",
    DELETE: "DELETE_CUSTOMER",
    ERROR: "ERROR_CUSTOMER",
    GET_ALL: "GET_ALL_CUSTOMER",
    GET_DETAIL: "GET_DETAIL_CUSTOMER",
    GET_DETAIL_SUCCESS: "GET_DETAIL_SUCCESS_CUSTOMER",
    UPDATE: "UPDATE_CUSTOMER", // final step
    UPDATE_UNSAVE: "UPDATE_UNSAVE_CUSTOMER",
    DELETE_UNSAVE: 'DELETE_UNSAVE_CUSTOMER',
    UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP_CUSTOMER',
}

export default CustomerTypes